// Light/dark switch
// The css uses media query in order to use colors based on browser preference
// this works even when javascript is disabled
// When javascript is enabled (this code runs), there is additionally a button to force
// the switch and remember the choice for the next time the page is visited
const darkToggleButtons = document.querySelectorAll<HTMLElement>('.dark-toggle');

type Theme = "light" | "dark";

function useTheme(theme: Theme, save = false) {
    darkToggleButtons.forEach(btn => {
        const moon = btn.querySelector<HTMLElement>('.moon');
        const sun = btn.querySelector<HTMLElement>('.sun');
        if (moon === null || sun === null)
            return;
        if(theme === "dark") {
            moon.style.display = 'none';
            sun.style.display = 'initial';
        } else {
            moon.style.display = 'initial';
            sun.style.display = 'none';
        }
    });

    document.body.classList.remove("light");
    document.body.classList.remove("dark");
    document.body.classList.add(theme);

    if(save)
        localStorage.setItem('theme', theme);
}

// Use theme saved in local storage if it exists
if(localStorage.theme === "dark")
    useTheme("dark");
else if(localStorage.theme === "light")
    useTheme("light");
// Otherwise look at what the browser prefers
else if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    useTheme("dark");
else
    useTheme("light");

darkToggleButtons.forEach(btn => {
    btn.addEventListener('click', () => {
        if (document.body.classList.contains('dark')) {
            useTheme("light", true);
        } else if(document.body.classList.contains('light')) {
            useTheme("dark", true);
        } else {
            console.error("If javascript is enabled, the body should always have either the light or dark class!");
        }
    });
});
